body {
    margin: 0;
    font-family: 'Proxima Nova',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url("bg.png");
    background-size: 100% 100%;
}

* {
    font-family: "Proxima Nova",sans-serif!important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#whole-app {
}

.filter-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1612px;
    margin: auto;
    border-radius: 14px;
    background: rgba(255, 255, 255, 0.8);
    padding: 24px;
    gap: 12px;
    margin-bottom: 16px;
}

.filter-control {
    flex-basis: calc(50% - 6px);
    width: calc(50% - 6px);
}

.filter-control.wide {
    flex-basis: 100%;
    width: 100%;
}

.filter-control .MuiFormControl-root {
    width: 100%;
}

.filter-control .MuiSelect-select {
    min-height: 18px;
    padding: 7px!important;
    font-family: 'Proxima Nova', sans-serif;
}

#grid-wrapper {
    max-width: 1660px;
    margin: auto;
}

/* to prevent shrinking width when there isn't much content in the cell */
.ag-cell-value {
    width: 100%;
}
.ag-theme-alpine .ag-root-wrapper {
    background-color: transparent!important;
    border: none!important;
}

.ag-header {
    display: none;
}

.cand-detail-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
}


.cand-detail-block {
    /*flex-basis: 50%;*/
    width: 100%;
    padding: 16px 16px 24px 0;
}
.cand-detail-block .elem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.cand-block-elem {
    display: flex;
    padding-top: 2px;
    padding-bottom: 2px;
}

.cand-block-elem-title {
    white-space: nowrap;
    padding-right: 4px;
}

.cand-block-elem-values {
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    max-height: 64px;
}

.cand-block-elem-values div {
    margin-top: 4px;
}

.MuiChip-root {
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 10px !important;
    height: 28px;
}

.grid-row {
    background-color: lightyellow;
}

.cand-detail-block .elem.top {
    justify-content: space-between;
}

/* Min width when it makes sense to display both blocks in one row */
@media (min-width: 640px) {
    .cand-detail-row {
        flex-direction: row;
    }

    .cand-detail-block {
        min-width: 0;
    }
}
/* filters inputs */
.filter-block .MuiInputBase-root,.MuiOutlinedInput-root,.filter-select-box,.MuiInputBase-formControl{
    border-radius: 8px;
    background-color: white;
}

.filter-block .PrivateNotchedOutline-root-1,.MuiOutlinedInput-notchedOutline{
    border: none;
}

/* filters labels */
.filter-block .MuiFormLabel-root,.MuiInputLabel-root,.MuiInputLabel-formControl,.MuiInputLabel-animated,.MuiInputLabel-outlined{
    font-size: 16px;
    font-weight: 400;
    color: #1A1A1C;
    transform: translate(8px, 8px) scale(1);
}

/* filters dropdown */
.filter-block .MuiPaper-root,.MuiMenu-paper,.MuiPopover-paper,.MuiPaper-elevation8,.MuiPaper-rounded{
    top: 73px !important;
}

/* filters checkbox */
.filter-block .checkbox .filter-control{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.filter-block .container{
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.filter-block .container p{
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    padding-left: 5px;
    padding-top: 3px;

}

.filter-block .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.filter-block .checkbox .filter-control input ~ .checkmark {
    background-color: #a9a9a9;
}

.filter-block .checkbox .container input:checked ~ .checkmark {
    background-color: #2196F3;
}

.filter-block .container input:checked ~ .checkmark:after {
    display: block;
}

.filter-block .container .checkmark:after {
    left: 3.5px;
    top: 1px;
    width: 3px;
    height: 6px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.filter-block .checkmark {
    position: absolute;
    top: 9px;
    left: 0;
    height: 12px;
    width: 12px;
    background-color: #0085FF;
    border-radius: 2px;
}

.container:hover input ~ .checkmark {
    background-color: #ccc;
}

.filter-block .checkmark:after {
    content: "";
    position: absolute;
    display: none;

}

.cand-block-elem-values{
    min-height: 57px;
}
.cand-block-elem-values::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
}
.cand-block-elem-values::-webkit-scrollbar-corner {
    background: transparent;
}
.cand-block-elem-values::-webkit-scrollbar-thumb {
    background: #888;
}


.account-button, .linkedin-button, .portfolio-button  {
    width: auto;
    min-width: unset!important;
    height: 33px;
    margin-right: 8px!important;
    background-color: #fff!important;
    background-repeat: no-repeat;
    background-size: auto 55%;
    background-position: 7px center;
    justify-content: start!important;
    box-shadow: none!important;
    border: 1px solid #2E81E6!important;
    border-radius: 8px!important;
}

.account-button span, .linkedin-button span, .portfolio-button span  {
    font-weight: 400!important;
    font-size: 16px!important;
    line-height: 19px!important;
    text-transform: capitalize!important;
    color: #1A1A1C!important;
}
@media (max-width: 768px){
    .account-button span, .linkedin-button span, .portfolio-button span  {
        font-size: 13px!important;
    }
}

.light {
    opacity: 0.6;
}

.seniority span {
    color: #1A1A1C;
    font-size: 10px;
    line-height: 12px;
    padding: 3px 6px;
    border: 1px solid #1A1A1C;
    border-radius: 3px;
    margin-left: 8px;
    font-weight: 700;
}

.country span,
.relocate,
.experience {
    font-size: 16px;
    line-height: 19px;
    color: #1A1A1C;
}

.title span {
    font-weight: 700;
    font-size: 22px;
    line-height: 38px;
    color: #1A1A1C;
}

.sm-title {
    font-size: 14px;
    line-height: 17px;
    margin-right: 4px;
    color: #1A1A1C;
}

.id {
    font-size: 12px;
    line-height: 15px;
    color: #1A1A1C;
}

.id span {
    margin-left: 8px;
}

.experience {
    margin-left: 24px;
}
@media (max-width: 768px){
    .experience {
        margin-left: 0;
    }
}

.elem > div {
    display: inline-flex;
    flex-wrap: wrap;
    white-space: nowrap;
}

.ag-theme-alpine {
    font-family: 'Proxima Nova', sans-serif;
}

.ag-center-cols-container {
    height: auto!important;
    min-height: unset!important;
    display: flex;
    flex-direction: column;
    gap: 4px;
}


.ag-theme-alpine .ag-row{
    background-color: transparent;
    height: auto!important;
    min-height: unset;
    max-height: unset;
    display: flex;
    position: relative;
    transform: none!important;
    border-width: 0px;
}
.ag-theme-alpine .ag-row .ag-cell .ag-react-container{
    border-radius: 14px;
    overflow: hidden;
    margin-bottom: 10px;
    color: #1A1A1C;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    background-color: white;
    padding-left: 17px;
    padding-right: 17px;
}

.ag-theme-alpine .ag-row .ag-cell {
    border-width: 0;
    position: relative;
    padding: 0;
}

.ag-theme-alpine .ag-row-hover .ag-cell .ag-react-container {
    background-color: white!important;
}


.ag-theme-alpine .ag-cell-wrapper {
    min-width: 100%;
}

.elem-values .MuiChip-root {
    font-family: 'Proxima Nova', sans-serif;
    height: auto;
    background: #E9EFF7;
    border-radius: 4px!important;
    margin: 2px;
}
.elem-values .MuiChip-root .MuiChip-label {
    font-size: 12px;
    line-height: 24px;
    color: #2E81E6;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 6px;
}

.info {
    margin-bottom: 16px;
}
@media (max-width: 768px){
    .info {
        flex-direction: column;
        align-items: flex-start!important;
    }
}

.buttons {
    margin-top: 16px;
}
.buttons button {
    padding: 7px 18px;
}
.ag-center-cols-clipper {
    height: auto!important;
}
.ag-paging-panel {
    background-color: white;
    border-radius: 14px;
    margin-top: 4px;
    font-family: "Proxima Nova", sans-serif;
    color: #1A1A1A;
}

.candidateRowCell {
    width: 100%;
}

.MuiOutlinedInput-input {
    padding: 0!important;
}
.MuiInputBase-inputTypeSearch{
    padding: 7px!important;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 7px!important;
}
